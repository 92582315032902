<template>
  <div class="store-set-box">
    <div class="store-set-title">填写基本信息</div>
    <div class="store-set-body">
      <div class="store-set-form">
        <el-form :model="form" :rules="rules" ref="form" label-width="120px">
          <el-form-item label="店铺名称：">
            <span style="color: #333333">{{store_name}}</span>
          </el-form-item>
          <el-form-item label="主营类目：">
            <span style="color: #333333">{{good_category_name}}</span>
          </el-form-item>
          <el-form-item label="店铺LOGO：" prop="store_logo">
            <div class="store-logo-src">
              <span class="logo-msg">图片尺寸要求为180*60的长方形图片，仅支持JPG、PNG和GIF</span>
              <div class="upload-img-box">
                <div class="upload-box" onclick="storeLogoSrcInput.click()">
                  <i class="iconfont">&#xe609;</i>
                  <span>上传logo</span>
                </div>
                <input
                    ref="modelStoreLogoSrcInput"
                    type="file"
                    id="storeLogoSrcInput"
                    @change="uploadStoreLogoSrc($event)"
                    style="display:none"
                    accept=".jpg,.jpeg,.gif,.png,"
                />
                <div class="upload-img" v-if="form.store_logo">
                  <img :src="hosturl + form.store_logo" alt="">
                  <i class="iconfont" @click="deleteUploadStoreLogoSrc">&#xe651;</i>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="店铺简介：" prop="intro">
            <div class="form-item-message" style="margin-bottom: 8px">店铺简介降显示在商店列表中，限制在20个字内</div>
            <el-input v-model="form.intro" style="width: 380px;"></el-input>
          </el-form-item>
          <el-form-item label="品牌简介：" prop="brand_intro">
            <vue-tinymce :setting="setting" v-model="form.brand_intro"></vue-tinymce>
          </el-form-item>
          <el-form-item style="margin-top: 210px">
            <el-button type="primary" @click="nextStep('form')">保存</el-button>
            <el-button @click=lastStep>返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <successDialog :successDialogVisible="successDialogVisible" @closeDialog="handleClose" />
  </div>
</template>

<script>
import {storesettingUpload, storeinfoSetting, storeinfoSet} from "@/utils/apis";
import successDialog from '@/components/successDialog.vue'
export default {
  name: "StoreSet",
  props:['stepValue'],
  components:{
    successDialog
  },
  data(){
    // 效验上传店铺logo
    var isStoreLogo = (rule, value, callback) => {
      if(!this.form.store_logo){
        callback(new Error("请上传店铺logo"));
      } else {
        callback();
      }
    };
    var isBrandIntro = (rule, value, callback) => {
      if(!this.form.brand_intro){
        callback(new Error("请输入品牌简介"));
      } else {
        callback();
      }
    };
    return {
      form:{
        id:'',
        store_logo:'',
        intro:'',
        brand_intro:'',
      },
      rules:{
        store_logo:[
          { required: true, validator: isStoreLogo,}
        ],
        intro:[
          { required: true, message: '请输入店铺简介', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        brand_intro:[
          { required: true, validator: isBrandIntro },
        ]
      },
      hosturl:localStorage.getItem('hosturl') || null,
      setting: {
        menubar: false,
        plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image,link",
        toolbar: [
          'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
          'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|link'
        ],
        language: 'zh_CN', //本地化设置
        height: 300,
        //上传图片
        images_upload_handler: function (blobInfo, success,failure,progress) {
          var xhr, formData;
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', 'storesetting/upload');//后端给的上传图片接口
          xhr.setRequestHeader("Authorization",localStorage.getItem('studentToken'));

          xhr.upload.onprogress = function(e){
            progress(e.loaded / e.total * 100);
          }

          xhr.onload = function() {
            var json;
            if (xhr.status == 403) {
              failure('HTTP Error: ' + xhr.status, { remove: true });
              return;
            }
            if (xhr.status < 200 || xhr.status >= 300 ) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
            if (!json || typeof json.data.src != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }
            success(json.data.src);
          };

          xhr.onerror = function () {
            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
          }

          formData = new FormData();
          formData.append('certificate', blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
        }
      },
      good_category_name:'',
      store_name:'',
      successDialogVisible:false,
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    this.getStoreinfoSetting();
  },
  methods:{
    // 获取店铺设置的答题内容
    getStoreinfoSetting(){
      let params = {};
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storeinfoSetting(params).then((res)=>{
        if(res.data.store_name){
          this.store_name = res.data.store_name;
          this.good_category_name = res.data.good_category_name;
        }
        if(res.data.id){
          this.form.id = res.data.id;
          this.form.store_logo = res.data.store_logo;
          this.form.intro = res.data.intro;
          this.form.brand_intro = res.data.brand_intro;
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 限制上传店铺logo宽高
    isWidthHeight(file){
      let _this = this;
      const isSize = new Promise((resolve, reject)=>{
        const width = 180;
        const height = 60;
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(()=>{
        return true
      },()=>{
        _this.$message.info('请上传尺寸为180*60px的图片');
        return false;
      })
      return isSize
    },
    // 上传店铺logo
    async uploadStoreLogoSrc(event){
      let file = event.target.files[0];
      let isWidth = await this.isWidthHeight(file);
      if(isWidth){
        let formData = new FormData();
        formData.append('certificate', file)
        storesettingUpload(formData).then((res)=>{
          this.$message.success(res.msg);
          this.form.store_logo = res.data.src;
        }).catch((err)=>{
          console.log('err',err)
        })
      }
    },
    // 删除店铺logo
    deleteUploadStoreLogoSrc(){
      this.form.store_logo = ''
    },
    lastStep(){
      this.$emit('update:stepValue', 5)
    },
    nextStep(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('store_logo', this.form.store_logo);
          formData.append('intro', this.form.intro);
          formData.append('brand_intro', this.form.brand_intro);
          if(this.form.id){
            formData.append('id', this.form.id)
          }
          if(this.opId){
            formData.append('course_id', this.courseId)
            formData.append('op_id', this.opId)
          }
          storeinfoSet(formData).then((res)=>{
            this.successDialogVisible = true
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false
        }
      })
    },
    handleClose(){
      this.successDialogVisible = false;
    }
  }
}
</script>

<style scoped lang="scss">
.store-set-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .store-set-title{
    font-size: 16px;
    color: #333;
    line-height: 1;
  }
  .store-set-body{
    display: flex;
    flex-direction: column;
    .store-set-form{
      margin: 22px 0 0 66px;
    }
  }
}
.store-logo-src{
  display: flex;
  flex-direction: column;
  .logo-msg{
    color: #333333;
    font-size: 14px;
  }
  .upload-img-box{
    margin-top: 9px;
    display: flex;
    .upload-img, .upload-box{
      &.upload-img{
        width: 180px;
        height: 60px;
        margin-right: 40px;
        position: relative;
        margin-left: 10px;
        i{
          line-height: 1;
          font-size: 12px;
          color: #F44336;
          position: absolute;
          right: -20px;
          top: 0;
          cursor: pointer;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.upload-box{
        width: 60px;
        height: 60px;
        border: 1px solid #DCDCDC;
        background: #F7F7F7;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1;
        justify-content: center;
        span{
          font-size: 12px;
          color: #666666;
          margin-top: 8px;
        }
        i{
          font-size: 16px;
          color: #BFBFBF;
        }
      }
    }
  }
}
::v-deep .el-form{
  .el-form-item{
    .el-form-item__label{
      line-height: 1;
    }
    .el-form-item__content{
      line-height: 1;
    }
  }
}
::v-deep .tox-tinymce {
  width: 80%;
}
</style>