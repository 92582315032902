<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-content-header">
        <span>1.信息提交前，请务必先了解<strong>京东开发平台招商资质标准细则；</strong></span>
        <span>2.公司信息需要项较多，建议先查看<strong>公司信息注意事项</strong>再进行填写；</span>
        <span>3.公司信息请严格按照相关证件信息进行确认填写；</span>
        <span>4.以下所需要上传电子版资质仅支持jpg、gif、png格式的图片，大小不超过1M，且必须加盖企业彩色公章。</span>
      </div>
      <div class="make-content-body">
        <div class="content-body-title">主体信息</div>
        <div class="content-body-form">
          <el-form :model="form" :rules="rules" ref="form" label-width="150px">
            <el-form-item label="主体类型：">
              <el-radio v-model="radio" label="1">企业</el-radio>
            </el-form-item>
            <el-form-item label="证件类型：" prop="document_type">
              <el-select v-model="form.document_type" style="width: 380px;" placeholder="请选择证件类型">
                <el-option label="多证合一营业执照（统一社会信用代码）" :value="0" :key="0"></el-option>
                <el-option label="多证合一营业执照（非统一社会信用代码）" :value="1" :key="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="营业执照电子版：" prop="business_license_src">
              <div class="business-license-src">
                <span>请上传清晰营业执照图片，营业执照复印件需加盖公司红章扫描上传，若营业执照上</span>
                <span>未体现注册资本、经营范围，请在营业执照后另行上传<strong>企业信息公示网</strong>上的截图</span>
                <div class="upload-img-box">
                  <div class="upload-img" v-if="businessLicenseImg">
                    <img :src="businessLicenseImg" alt="">
                    <i class="iconfont" @click="deleteUploadBusinessLicense">&#xe651;</i>
                  </div>
                  <div class="upload-box" onclick="businessLicenseInput.click()">
                    <i class="iconfont">&#xe609;</i>
                  </div>
                  <input
                      ref="modelBusinessLicenseInput"
                      type="file"
                      id="businessLicenseInput"
                      @change="uploadBusinessLicense($event)"
                      style="display:none"
                      accept=".jpg,.jpeg,.gif,.png,"
                  />
                </div>
                <span>图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持jpg、gif、png格式，最多可上传1张</span>
              </div>
            </el-form-item>
            <el-form-item label="公司名称：" prop="corporate_name">
              <el-input v-model="form.corporate_name" style="width: 380px;"></el-input>
              <div class="form-item-message">请按照营业执照上登记的完整名称填写，如有（），请在输入法为中文状态下输入</div>
            </el-form-item>
            <el-form-item label="统一社会信用代码：" prop="unified_social_credit_code">
              <el-input placeholder="15位的数字" v-model.number="form.unified_social_credit_code" style="width: 380px;"></el-input>
              <div class="form-item-message">请按照营业执照上的注册号进行填写</div>
            </el-form-item>
            <el-form-item label="营业执照所在地：" required class="select-address">
              <el-col>
                <el-form-item prop="business_license_location_province">
                  <el-select style="width: 117px;" v-model="form.business_license_location_province" @change="selectBusinessProvince($event,1)" placeholder="选择省">
                    <el-option
                        v-for="item in businessProvince"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="business_license_location_city">
                  <el-select style="width: 116px;margin: 0 10px" v-model="form.business_license_location_city" @change="selectBusinessProvince($event,2)" placeholder="选择市">
                    <el-option
                        v-for="item in businessCity"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="business_license_location_area">
                  <el-select style="width: 116px;" v-model="form.business_license_location_area" @change="selectBusinessProvince($event,3)" placeholder="选择区">
                    <el-option
                        v-for="item in businessArea"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <div class="form-item-message">请按照营业执照上的注册详细地址填写</div>
            </el-form-item>
            <el-form-item label="营业执照详细地址：" prop="business_license_address">
              <el-input v-model="form.business_license_address" style="width: 380px;"></el-input>
            </el-form-item>
            <el-form-item label="成立日期：" prop="incorporation_date">
              <el-date-picker
                  prefix-icon="el-icon-date"
                  style="width: 380px;"
                  v-model="form.incorporation_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="营业期限：" required class="select-business-data">
              <el-col v-if="form.business_date_type !== 1">
                <el-form-item prop="business_date_begin">
                  <el-date-picker
                      @change="selectBusinessDate"
                      prefix-icon="el-icon-date"
                      style="width: 150px;"
                      v-model="form.business_date_begin"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始时间">
                  </el-date-picker>
                </el-form-item> <span style="margin: 0 8px">-</span>
                <el-form-item prop="business_date_end">
                  <el-date-picker
                      @change="selectBusinessDate"
                      prefix-icon="el-icon-date"
                      style="width: 150px;"
                      v-model="form.business_date_end"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择结束时间">
                  </el-date-picker>
                </el-form-item>
                <span style="margin-left: 10px"><el-checkbox v-model="isLongTerm" @change="selectLongTerm">长期</el-checkbox></span>
              </el-col>
              <el-col v-else>
                <el-form-item prop="business_date_begin">
                  <el-date-picker
                      prefix-icon="el-icon-date"
                      style="width: 150px;"
                      v-model="form.business_date_begin"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始时间">
                  </el-date-picker>
                </el-form-item>
                <span style="margin-left: 10px"><el-checkbox v-model="isLongTerm" @change="selectLongTerm">长期</el-checkbox></span>
              </el-col>
            </el-form-item>
            <el-form-item label="注册资本（万元）：" prop="registered_capital">
              <el-input-number v-model="form.registered_capital" style="width: 380px;" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>
              <div class="form-item-message">若注册资本非人民币，请按照当前汇率换算人民币填写</div>
            </el-form-item>
            <el-form-item label="经营范围：" prop="business_scope">
              <el-input type="textarea" placeholder="请输入经营范围" style="width: 380px;" v-model="form.business_scope"></el-input>
              <div class="form-item-message">请与营业执照或企业信息公示网的经营范围保持一致</div>
            </el-form-item>
            <el-form-item label="法定代表人证件类型：" prop="legal_representative_certificate_type">
              <el-select v-model="form.legal_representative_certificate_type" style="width: 380px;" placeholder="请选择">
                <el-option v-for="item in legalRepresentative" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="法人证件电子版：" prop="id_card_front_src">
              <div class="legal-person-papers">
                <span>请按顺序分别上传正面（带有照片一面）和反面电子版图片，复印件请加盖开店公司红章</span>
                <div class="upload-img-box">
                  <div class="upload-img" v-if="form.id_card_front_src">
                    <img :src="hosturl + form.id_card_front_src" alt="">
                    <i class="iconfont" @click="deleteIdCardSrc(1)">&#xe651;</i>
                  </div>
                  <div class="upload-img" v-if="form.id_card_reserve_src">
                    <img :src="hosturl + form.id_card_reserve_src" alt="">
                    <i class="iconfont" @click="deleteIdCardSrc(2)">&#xe651;</i>
                  </div>
                  <div class="upload-box" onclick="idCardFrontSrcInput.click()">
                    <i class="iconfont">&#xe609;</i>
                  </div>
                  <input
                      ref="modelIdCardFrontSrcInput"
                      type="file"
                      id="idCardFrontSrcInput"
                      @change="uploadIdCardFrontSrc($event)"
                      style="display:none"
                      accept=".jpg,.jpeg,.gif,.png,"
                  />
                </div>
                <span>图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持jpg、gif、png格式，最多可上传2张</span>
              </div>
            </el-form-item>
            <el-form-item label="法定代表人姓名：" prop="legal_representative_name">
              <el-input v-model="form.legal_representative_name" style="width: 380px;"></el-input>
              <div class="form-item-message">请按照营业执照上登记的法人填写</div>
            </el-form-item>
            <el-form-item label="法定代表人证件号：" prop="legal_representative_certificates_num">
              <el-input v-model="form.legal_representative_certificates_num" style="width: 380px;"></el-input>
            </el-form-item>
            <el-form-item label="有效期：" required class="select-business-data">
              <el-col v-if="form.alidity_period_type !== 1">
                <el-form-item prop="alidity_period_begin">
                  <el-date-picker
                      @change="selectAlidityPeriod"
                      prefix-icon="el-icon-date"
                      style="width: 150px;"
                      v-model="form.alidity_period_begin"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始时间">
                  </el-date-picker>
                </el-form-item> <span style="margin: 0 6px">-</span>
                <el-form-item prop="alidity_period_end">
                  <el-date-picker
                      @change="selectAlidityPeriod"
                      prefix-icon="el-icon-date"
                      style="width: 150px;"
                      v-model="form.alidity_period_end"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择结束时间">
                  </el-date-picker>
                </el-form-item>
                <span style="margin-left: 10px"><el-checkbox v-model="isLongTermRepresentative" @change="selectLongTermRepresentative">长期</el-checkbox></span>
              </el-col>
              <el-col v-else>
                <el-form-item prop="alidity_period_begin">
                  <el-date-picker
                      prefix-icon="el-icon-date"
                      style="width: 150px;"
                      v-model="form.alidity_period_begin"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始时间">
                  </el-date-picker>
                </el-form-item>
                <span style="margin-left: 10px"><el-checkbox v-model="isLongTermRepresentative" @change="selectLongTermRepresentative">长期</el-checkbox></span>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="lastStep">上一步</el-button>
      <el-button @click="nextStep('form')" type="primary" style="margin-left: 20px">下一步，完善主体信息</el-button>
    </div>
  </div>
</template>

<script>
import {storesettingUpload, storesettingTwo, storesettingAddStepTwo} from '@/utils/apis'
import {areaList} from '@/utils/area'
export default {
  name: "BodyMessageTwo",
  props:['stepValue'],
  data(){
    // 效验上传营业执照电子版
    var businessLicenseSrc = (rule, value, callback) => {
      if(!this.form.business_license_src){
        callback(new Error("请上传营业执照电子版"));
      } else {
        callback();
      }
    };
    // 验证统一社会信用代码
    var isUnifiedSocialCreditCode = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入统一社会信用代码'));
      } else {
        if(!Number.isInteger(value)){
          callback(new Error('输入格式为纯数字'));
        } else {
          let str = '' + value;
          if(str.length !== 15){
            callback(new Error('长度为15位'));
          } else {
            callback();
          }
        }
      }
    };
    // 效验上传法人证件电子版
    var isIdCardFrontSrc = (rule, value, callback) => {
      if(this.form.id_card_front_src){
        if(this.form.id_card_reserve_src){
          callback();
        } else {
          callback(new Error('请上传法人证件反面'))
        }
      } else {
        callback(new Error('请上传法人证件正面'))
      }
    }
    return {
      form:{
        id:'',
        document_type:'',
        business_license_src:'',
        corporate_name:'',
        unified_social_credit_code:'',
        business_license_location_province:'',
        business_license_location_city:'',
        business_license_location_area:'',
        business_license_address:'',
        incorporation_date:'',
        business_date_begin:'',
        business_date_end:'',
        business_date_type:0,
        registered_capital:void 0,
        business_scope:'',
        legal_representative_certificate_type:'',
        legal_representative_name:'',
        legal_representative_certificates_num:'',
        alidity_period_begin:'',
        alidity_period_end:'',
        alidity_period_type:0,
        id_card_front_src:'',
        id_card_reserve_src:'',
      },
      rules:{
        document_type:[
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        business_license_src:[
          { required: true, validator: businessLicenseSrc,}
        ],
        corporate_name:[
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        unified_social_credit_code:[
          { required: true, validator:isUnifiedSocialCreditCode, trigger: 'blur'}
        ],
        business_license_location_province:[
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        business_license_location_city:[
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        business_license_location_area:[
          { required: true, message: '请选择区', trigger: 'change' }
        ],
        business_license_address:[
          { required: true, message: '请输入营业执照详细地址', trigger: 'blur' }
        ],
        incorporation_date:[
          { type: 'string', required: true, message: '请选择成立日期', trigger: 'change' }
        ],
        business_date_begin:[
          { type: 'string', required: true, message: '请选择营业开始日期', trigger: 'change' }
        ],
        business_date_end:[
          { type: 'string', required: true, message: '请选择营业结束日期', trigger: 'change' }
        ],
        registered_capital:[
          { required: true, message: '请输入注册资本', trigger: 'blur' }
        ],
        business_scope:[
          { required: true, message: '请输入经营范围', trigger: 'blur' }
        ],
        legal_representative_certificate_type:[
          { required: true, message: '请选择法定代表人证件类型', trigger: 'change' }
        ],
        legal_representative_name:[
          { required: true, message: '请输入法定代表人姓名', trigger: 'blur' }
        ],
        legal_representative_certificates_num:[
          { required: true, message: '请输入法定代表人证件号', trigger: 'blur' }
        ],
        alidity_period_begin:[
          { type: 'string', required: true, message: '请选择有效期开始日期', trigger: 'change' }
        ],
        alidity_period_end:[
          { type: 'string', required: true, message: '请选择有效期结束日期', trigger: 'change' }
        ],
        id_card_front_src:[
          { required: true, validator: isIdCardFrontSrc,}
        ]
      },
      radio:'1',
      hosturl:localStorage.getItem('hosturl') || null,
      businessLicenseImg:null,
      // 营业执照选择地区
      businessProvince:[],
      businessCity:[],
      businessArea:[],
      isLongTerm:false,//营业期限是否长期
      //法定代表人数组
      legalRepresentative:[
        {
          id:0,
          name:'大陆身份证'
        },
        {
          id:1,
          name:'护照'
        },
        {
          id:2,
          name:'港澳居民通行证'
        },
        {
          id:3,
          name:'台湾居民通行证'
        },
      ],
      isLongTermRepresentative:false, //法人代表长期
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    areaList.forEach(item=>{
      this.businessProvince.push(item);
    })
    this.getStoresettingTwo()
  },
  methods:{
    // 获取学生主题信息2的答题内容
    getStoresettingTwo(){
      let params = {}
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storesettingTwo(params).then((res)=>{
        if(res.data.id){
          for(let i in this.form){
            if(i === 'unified_social_credit_code'){
              this.form[i] = Number(res.data[i])
            } else {
              this.form[i] = res.data[i]
            }
          }
          this.businessLicenseImg = this.hosturl + this.form.business_license_src;
          this.selectBusinessProvince(res.data.business_license_location_province,1);
          this.form.business_license_location_province = res.data.business_license_location_province;
          this.selectBusinessProvince(res.data.business_license_location_city,2);
          this.form.business_license_location_city = res.data.business_license_location_city;
          this.form.business_license_location_area = res.data.business_license_location_area;
          if(this.form.business_date_type === 1){
            this.isLongTerm = true
          } else {
            this.isLongTerm = false
          }
          if(this.form.alidity_period_type === 1){
            this.isLongTermRepresentative = true
          } else {
            this.isLongTermRepresentative = false
          }
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 上传营业执照电子版
    uploadBusinessLicense(event){
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append('certificate', file)
      storesettingUpload(formData).then((res)=>{
        this.$message.success(res.msg);
        this.form.business_license_src = res.data.src;
        this.businessLicenseImg = this.hosturl + res.data.src;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 删除营业执照电子版
    deleteUploadBusinessLicense(){
      this.form.business_license_src = '';
      this.businessLicenseImg = null;
    },
    // 上传法人证件电子版
    uploadIdCardFrontSrc(event){
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append('certificate', file);
      storesettingUpload(formData).then((res)=>{
        if(this.form.id_card_front_src){
          this.form.id_card_reserve_src = res.data.src;
        } else {
          this.form.id_card_front_src = res.data.src;
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 删除法人证件电子版
    deleteIdCardSrc(num){
      if(num === 1){
        this.form.id_card_front_src = ''
      } else {
        this.form.id_card_reserve_src = ''
      }
    },
    //营业执照-选择地区
    selectBusinessProvince(val,type){
      if(type === 1){
        this.form.business_license_location_city = '';
        this.form.business_license_location_area = '';
        this.businessCity = [];
        this.businessArea = [];
        let length = this.businessProvince.length;
        for(let i = 0; i < length; i++){
          if(this.businessProvince[i].name === val){
            this.businessCity = this.businessProvince[i].city;
            return
          }
        }
      } else if(type === 2){
        this.form.business_license_location_area = '';
        this.businessArea = [];
        let length = this.businessCity.length;
        for(let i = 0; i < length;i++){
          if(this.businessCity[i].name === val){
            this.businessArea = this.businessCity[i].area
            return
          }
        }
      }
    },
    // 选择营业期限时间
    selectBusinessDate(){
      if(this.form.business_date_type !== 1){
        if(this.form.business_date_begin && this.form.business_date_end){
          if(this.form.business_date_begin > this.form.business_date_end){
            this.$nextTick(()=>{
              this.$set(this.form, 'business_date_end', '');
              this.$message('结束日期不能大于开始日期！');
            })
          }
        }
      }
    },
    // 点击营业期限是否长期
    selectLongTerm(val){
      if(val){
        this.form.business_date_type = 1
      } else {
        this.form.business_date_type = 0
      }
    },
    // 选择待定代表人有效期
    selectAlidityPeriod(){
      if(this.form.alidity_period_type !== 1){
        if(this.form.alidity_period_begin && this.form.alidity_period_end){
          if(this.form.alidity_period_begin > this.form.alidity_period_end){
            this.$nextTick(()=>{
              this.$set(this.form, 'alidity_period_end', '');
              this.$message('结束日期不能大于开始日期！');
            })
          }
        }
      }
    },
    // 点击法定代表人是否长期
    selectLongTermRepresentative(val){
      if(val){
        this.form.alidity_period_type = 1
      } else {
        this.form.alidity_period_type = 0
      }
    },
    lastStep(){
      this.$emit('update:stepValue',2)
    },
    nextStep(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('document_type', this.form.document_type);
          formData.append('corporate_name', this.form.corporate_name);
          formData.append('unified_social_credit_code', this.form.unified_social_credit_code);
          formData.append('business_license_location_province', this.form.business_license_location_province);
          formData.append('business_license_location_city', this.form.business_license_location_city);
          formData.append('business_license_location_area', this.form.business_license_location_area);
          formData.append('business_license_address', this.form.business_license_address);
          formData.append('incorporation_date', this.form.incorporation_date);
          formData.append('business_date_type', this.form.business_date_type);
          formData.append('business_date_begin', this.form.business_date_begin);
          if(this.form.business_date_type === 0){
            formData.append('business_date_end', this.form.business_date_end);
          }
          formData.append('registered_capital', this.form.registered_capital);
          formData.append('business_scope', this.form.business_scope);
          formData.append('legal_representative_certificate_type', this.form.legal_representative_certificate_type);
          formData.append('legal_representative_name', this.form.legal_representative_name);
          formData.append('legal_representative_certificates_num', this.form.legal_representative_certificates_num);
          formData.append('alidity_period_type', this.form.alidity_period_type);
          formData.append('alidity_period_begin', this.form.alidity_period_begin);
          if(this.form.alidity_period_type === 0){
            formData.append('alidity_period_end', this.form.alidity_period_end);
          }
          formData.append('business_license_src',this.form.business_license_src);
          formData.append('id_card_front_src',this.form.id_card_front_src);
          formData.append('id_card_reserve_src',this.form.id_card_reserve_src);
          if(this.form.id){
            formData.append('id',this.form.id);
          }
          if(this.opId){
            formData.append('course_id',this.courseId);
            formData.append('op_id',this.opId);
          }
          storesettingAddStepTwo(formData).then((res)=>{
            this.$emit('update:stepValue',2.2)
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 8px 46px;
      span{
        font-size: 14px;
        color: #333;
        font-weight: 400;
        line-height: 30px;
        strong{
          font-weight: 400;
          color: #2461EF;
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .content-body-title{
        font-size: 16px;
        line-height: 1;
        margin: 38px 0 0 0;
      }
      .content-body-form{
        margin: 16px 0 0 15px;
        ::v-deep .el-form{
          .el-form-item{
            .el-form-item__label{
              padding: 0;
            }
          }
          .form-item-message{
            line-height: 1;
            font-size: 12px;
            color: #999999;
            padding: 10px 0 4px;
          }
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
.business-license-src{
  display: flex;
  flex-direction: column;
  span{
    //line-height: 1;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    &:nth-child(2){
      line-height: 1;
    }
    &:last-of-type{
      padding: 10px 0 4px;
      color: #999999;
      font-size: 12px;
      line-height: 1;
    }
    strong{
      font-weight: 400;
      color: #2461EF;
    }
  }
  .upload-img-box{
    margin-top: 10px;
    display: flex;
    .upload-img, .upload-box{
      width: 120px;
      height: 120px;
      &.upload-img{
        margin-right: 40px;
        position: relative;
        i{
          line-height: 1;
          font-size: 12px;
          color: #F44336;
          position: absolute;
          right: -20px;
          top: 0;
          cursor: pointer;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.upload-box{
        background: #F7F7F7;
        text-align: center;
        line-height: 120px;
        cursor: pointer;
        i{
          font-size: 24px;
          color: #BFBFBF;
        }
      }
    }
  }
}
.legal-person-papers{
  display: flex;
  flex-direction: column;
  span{
    color: #999999;
    font-size: 12px;
  }
  .upload-img-box{
    //margin-top: 10px;
    display: flex;
    .upload-img, .upload-box{
      width: 120px;
      height: 120px;
      &.upload-img{
        margin-right: 40px;
        position: relative;
        i{
          line-height: 1;
          font-size: 12px;
          color: #F44336;
          position: absolute;
          right: -20px;
          top: 0;
          cursor: pointer;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.upload-box{
        background: #F7F7F7;
        text-align: center;
        line-height: 120px;
        cursor: pointer;
        i{
          font-size: 24px;
          color: #BFBFBF;
        }
      }
    }
  }
}
::v-deep .select-address .el-form-item__content {
  display: flex;
  flex-direction: column;
  .el-col{
    display: flex;
    .is-error{
      .el-form-item__error{
        top: calc(100% + 26px);
      }
    }
  }
}
::v-deep .select-business-data .el-form-item__content {
  display: flex;
  flex-direction: column;
  .el-col{
    display: flex;
  }
}
::v-deep .el-input-number .el-input__inner{
  text-align: left;
}
</style>