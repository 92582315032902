<template>
  <div class="make-box">
    <el-scrollbar class="make-scrollbar" :native="false">
      <div class="make-scrollbar-box" v-if="stepValue !== 6">
        <div class="make-scrollbar-header">
          <div class="logo-box">
            <img src="./../../../../assets/images/student/JD-logo2.png" alt="">
          </div>
        </div>
        <div class="make-scrollbar-main">
          <div class="scrollbar-main-flow">
            <div class="main-flow-header">
              <div class="step-circle" :class="{'current':stepValue > 0}">
                <span class="step-num">1</span>
                <span class="step-text">准备资料</span>
              </div>
              <div class="step-line" :class="{'current':stepValue > 1}"></div>
              <div class="step-circle" :class="{'current':stepValue > 1}"><span class="step-text">主体信息</span></div>
              <div class="step-line" :class="{'current':stepValue > 2.3}"></div>
              <div class="step-circle" :class="{'current':stepValue > 2.3}"><span class="step-text">店铺信息</span></div>
              <div class="step-line" :class="{'current':stepValue > 3}"></div>
              <div class="step-circle" :class="{'current':stepValue > 3}"><span class="step-text">店铺命名</span></div>
              <div class="step-line" :class="{'current':stepValue > 4}"></div>
              <div class="step-circle" :class="{'current':stepValue > 4}">
                <span class="step-num">2</span>
                <span class="step-text">提交审核</span>
              </div>
            </div>
            <div class="main-flow-settled">
              <PrepareMaterial :step-value.sync="stepValue" v-if="stepValue === 1" />
              <BodyMessageOne :step-value.sync="stepValue" v-if="stepValue === 2" />
              <BodyMessageTwo :step-value.sync="stepValue" v-if="stepValue === 2.1" />
              <BodyMessageThree :step-value.sync="stepValue" v-if="stepValue === 2.2" />
              <StoreInformation :step-value.sync="stepValue" v-if="stepValue === 3" />
              <StoreName :step-value.sync="stepValue" v-if="stepValue === 4" />
              <SubmitAudit :step-value.sync="stepValue" v-if="stepValue === 5" />
            </div>
<!--            <div class="main-flow-footer">-->
<!--              <el-button>返回重选</el-button>-->
<!--              <el-button type="primary" style="margin-left: 20px">开始提交</el-button>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="make-store-set" v-else>
        <StoreSet :step-value.sync="stepValue" />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import PrepareMaterial from '@/components/student/exam/storeDecoration/PrepareMaterial.vue'
import BodyMessageOne from '@/components/student/exam/storeDecoration/BodyMessageOne.vue'
import BodyMessageTwo from '@/components/student/exam/storeDecoration/BodyMessageTwo.vue'
import BodyMessageThree from '@/components/student/exam/storeDecoration/BodyMessageThree.vue'
import StoreInformation from '@/components/student/exam/storeDecoration/StoreInformation.vue'
import StoreName from '@/components/student/exam/storeDecoration/StoreName.vue'
import SubmitAudit from '@/components/student/exam/storeDecoration/SubmitAudit.vue'
import StoreSet from '@/components/student/exam/storeDecoration/StoreSet.vue'
export default {
  name: "StoreSignAndSet",
  components:{
    PrepareMaterial,
    BodyMessageOne,
    BodyMessageTwo,
    BodyMessageThree,
    StoreInformation,
    StoreName,
    SubmitAudit,
    StoreSet
  },
  data(){
    return {
      stepValue:1
    }
  }
}
</script>

<style scoped lang="scss">
.make-box{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .make-scrollbar{
    flex: 1;
    height: 1%;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
  }
  .make-scrollbar-box{
    display: flex;
    flex-direction: column;
    .make-scrollbar-header{
      padding: 0 20px;
      .logo-box{
        width: 227px;
        height: 40px;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .make-scrollbar-main{
      display: flex;
      justify-content: center;
      .scrollbar-main-flow{
        border: 1px solid #E5E5E5;
        margin: 20px 0 20px 20px;
        display: flex;
        flex-direction: column;
        .main-flow-header{
          background: #F9F9F9;
          display: flex;
          align-items: center;
          height: 88px;
          padding: 0 74px 16px;
          box-sizing: border-box;
          .step-circle{
            width: 20px;
            height: 20px;
            background: #D5D5D5;
            border-radius: 50%;
            text-align: center;
            position: relative;
            .step-num{
              font-size: 16px;
              color: #fff;
            }
            &:first-of-type,&:last-of-type{
              width: 32px;
              height: 32px;
              .step-num{
                line-height: 32px;
              }
              .step-text{
                bottom: -22px;
                left: -12px;
              }
            }
            .step-text{
              display: inline-block;
              font-size: 14px;
              color: #999999;
              line-height: 1;
              position: absolute;
              bottom: -28px;
              left: -18px;
              width: 56px;
            }
            &.current{
              background: #2DC079;
              .step-text{
                color: #2DC079;
              }
            }
          }
          .step-line{
            width: 254px;
            background: #D5D5D5;
            height: 3px;
            &.current{
              background: #2DC079;
            }
          }
        }
        .main-flow-settled{
          display: flex;
        }
        //.main-flow-footer{
        //  padding: 10px 72px 10px 0;
        //  //box-sizing: border-box;
        //  text-align: right;
        //  border-top: solid #DCDCDC 1px;
        //}
      }
    }
  }
  .make-store-set{
    display: flex;
  }
}
</style>