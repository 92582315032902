<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-content-header">
        <span>1.信息提交前，请务必先了解<strong>京东开发平台招商资质标准细则；</strong></span>
        <span>2.公司信息需要项较多，建议先查看<strong>公司信息注意事项</strong>再进行填写；</span>
        <span>3.若所选店铺类型为旗舰店或专卖店，相关授权模板请见：<strong>旗舰店类模板；专卖店类模板；</strong></span>
        <span>4.以下所需要上传电子版资质仅支持jpg、gif、png格式的图片，大小不超过1M，且必须加盖企业彩色公章。</span>
      </div>
      <div class="make-content-body">
        <div class="content-body-title">
          店铺名称
          <span>请按命名规范进行命名，若店铺类型不是旗舰店，则店铺名称不可为xxx旗舰店，点击查看<a href="https://rule.jd.com/rule/ruleDetail.action?ruleId=2598" target="_blank">店铺命名详细规则</a></span>
        </div>
        <div class="content-body-form">
          <el-form :model="form" :rules="rules" ref="form" label-width="178px">
            <el-form-item label="店铺类型：" prop="store_type">
              <el-radio-group v-model="form.store_type" @change="selectStoreType">
                <el-radio :label="0">旗舰店</el-radio>
                <el-radio :label="1">专营店</el-radio>
                <el-radio :label="2">专卖店</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="主营类目：" prop="good_category_id">
              <el-cascader
                  style="width: 274px;"
                  :props="categoryProps"
                  :disabled="true"
                  v-model="form.good_category_id"
                  :options="goodCategoryList"></el-cascader>
              <el-button type="primary" style="margin-left: 8px" @click="isShowCategory = true">选择类目</el-button>
            </el-form-item>
            <el-form-item label="专营店命名规范：">
              <span style="font-size: 14px;color: #333">公司简称+类目名+专营店</span>
            </el-form-item>
            <el-form-item label="公司简称：" prop="company_abbreviation">
              <el-input  style="width: 380px;" placeholder="请输入公司简称" v-model="form.company_abbreviation"></el-input>
              <div class="form-item-message">如：北京京东世纪贸易有限公司简称“京东”</div>
            </el-form-item>
            <el-form-item label="经营类目描述：" prop="category_description">
              <el-input  style="width: 380px;" placeholder="非必填" v-model="form.category_description"></el-input>
              <div class="form-item-message">如：化妆品、服饰、男鞋等</div>
            </el-form-item>
            <el-form-item label="店铺后缀：" prop="shop_suffix">
              <el-select v-model="form.shop_suffix" style="width: 380px;" placeholder="请选择店铺后缀">
                <el-option v-if="form.store_type === 1"  :key="0" :value="0" label="专营店"></el-option>
                <el-option v-if="form.store_type === 2"  :key="1" :value="1" label="专卖店"></el-option>
                <el-option v-if="form.store_type === 0" :key="2" :value="2" label="旗舰店"></el-option>
                <el-option v-if="form.store_type === 0"  :key="3" :value="3" label="官方旗舰店"></el-option>
              </el-select>
              <div class="form-item-message">请选择您期望的店铺后缀名</div>
            </el-form-item>
            <el-form-item label="期望店铺名称：">
              <span style="color: #333333;font-size: 14px">{{form.company_abbreviation + form.category_description}}{{findStoreName(form.shop_suffix)}}</span>
              <div class="form-item-message">仅作为参考，最终以审核通过的店铺名称为准</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-dialog
          @open="openDialog"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          title="请选择类目"
          custom-class="green-dialog"
          :visible.sync="isShowCategory"
          width="650px">
        <i class="iconfont close-icon"  @click="closeDialog">&#xe668;</i>
        <el-cascader-panel v-model="form.good_category_id" :props="categoryProps" :options="goodCategoryList"></el-cascader-panel>
        <div slot="footer" style="text-align: center">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="submitAllocation" style="margin-left: 30px">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="footer-btn">
      <el-button @click="lastStep">上一步</el-button>
      <el-button @click="nextStep('form')" type="primary" style="margin-left: 20px">提交申请</el-button>
    </div>
  </div>
</template>

<script>
import {adminGoodGetCategory, storeinfoName, storeinfoAddname} from '@/utils/apis'
export default {
  name: "StoreName",
  props:['stepValue'],
  data(){
    return {
      form:{
        id:'',
        store_type:'',
        good_category_id:null,
        company_abbreviation:'',
        category_description:'',
        shop_suffix:'',
      },
      rules:{
        store_type:[
          { required: true, message: '请选择店铺类型', trigger: 'change' }
        ],
        good_category_id:[
          { required: true, message: '请选择主营类目', trigger: 'blur' }
        ],
        company_abbreviation:[
          { required: true, message: '请输入公司简称', trigger: 'blur' }
        ],
        shop_suffix:[
          { required: true, message: '请选择店铺后缀', trigger: 'change' }
        ]
      },
      categoryProps:{value:'id',label:'name'},
      goodCategoryList:[],
      isShowCategory:false,
      lastCategoryId:null,
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    this.getAdminGoodGetCategory();
    this.getStoreinfoName()
  },
  methods:{
    // 学生获取店铺命名答题内容
    getStoreinfoName(){
      let params = {};
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storeinfoName(params).then((res)=>{
        if(res.data.id){
          for(let i in this.form){
            if(i === 'shop_suffix'){
              this.form[i] = Number(res.data[i])
            } else if (i === 'good_category_id'){
              this.form[i] = res.data['goods_category_id']
            } else {
              this.form[i] = res.data[i]
            }
          }
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 查找店铺后缀名字
    findStoreName(id){
      let str = '专卖店';
      if(id !== ''){
        switch (id){
          case 0:
            str = '专营店';
            break;
          case 1:
            str = '专卖店';
            break;
          case 2:
            str = '旗舰店';
            break;
          case 3:
            str = '官方旗舰店';
            break;
        }
      }
      return str;
    },
    // 获取类目
    getAdminGoodGetCategory(){
      adminGoodGetCategory({}).then((res)=>{
        this.goodCategoryList = res.data
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    // 打开获取类目
    openDialog(){
      this.lastCategoryId = JSON.parse(JSON.stringify(this.form.good_category_id));
    },
    // 取消类目
    closeDialog(){
      this.form.good_category_id = JSON.parse(JSON.stringify(this.lastCategoryId))
      this.isShowCategory = false
    },
    // 确定选择类目
    submitAllocation(){
      if(this.form.good_category_id.length){
        this.isShowCategory = false;
      } else {
        this.$message('请选择类目')
      }
    },
    selectStoreType(){
      this.form.shop_suffix = ''
    },
    lastStep(){
      this.$emit('update:stepValue', 3)
    },
    nextStep(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('store_type', this.form.store_type);
          formData.append('good_category_id', this.form.good_category_id[this.form.good_category_id.length - 1]);
          formData.append('company_abbreviation', this.form.company_abbreviation);
          if(this.form.category_description){
           formData.append('category_description', this.form.category_description)
          }
          formData.append('shop_suffix', this.form.shop_suffix);
          if(this.form.id){
            formData.append('id', this.form.id)
          }
          if(this.opId){
            formData.append('course_id', this.courseId)
            formData.append('op_id', this.opId)
          }
          storeinfoAddname(formData).then((res)=>{
            this.$emit('update:stepValue', 5)
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 8px 46px;
      span{
        font-size: 14px;
        color: #333;
        font-weight: 400;
        line-height: 30px;
        strong{
          font-weight: 400;
          color: #2461EF;
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .content-body-title{
        font-size: 16px;
        line-height: 1;
        margin: 30px 0 0 0;
        span{
          font-size: 12px;
          color: #666666;
          margin-left: 15px;
          a{
            color: #2461EF;
            text-decoration: none;
          }
        }
      }
      .content-body-form{
        margin: 6px 0 0 0px;
        ::v-deep .el-form{
          .el-form-item{
            .el-form-item__label{
              padding: 0;
            }
          }
          .form-item-message{
            line-height: 1;
            font-size: 12px;
            color: #999999;
            padding: 10px 0 4px;
          }
          .el-cascader{
            .is-disabled{
              .el-input__inner{
                padding-right: 0;
              }
              .el-input__suffix{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
.green-dialog{
  position: relative;
  .close-icon{
    position: absolute;
    right: 26px;
    top: 18px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}
::v-deep .el-cascader-panel{
  height: 287px;
  .el-cascader-menu__wrap {
    height: 287px;
    .in-active-path{
      background: #E7F6EF;
    }
  }
}
</style>