<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-box-header">
        京东秉承“客户为先，诚信至上”的宗旨、“让生活变得简单，快乐！”为我们的最终目标，为广大用户提供最优质的商品及服务。京东开放平台欢迎国际国内知名品牌、拥有特色货品及垂直电商入驻。
      </div>
      <div class="make-settled-one">
        <div class="make-settled-title">一.商家入驻流程：</div>
        <div class="make-settled-table">
          <div class="settled-table-body">
            <div class="table-body-item">准备材料<i class="iconfont arrows">&#xe657;</i></div>
            <div class="table-body-item">准备公司资质</div>
            <div class="table-body-item">准备经营资质</div>
            <div class="table-body-item">开通京东钱包<i class="iconfont warning">&#xe685;</i></div>
          </div>
          <div class="settled-table-body">
            <div class="table-body-item">提交入驻<i class="iconfont arrows">&#xe657;</i></div>
            <div class="table-body-item">提交公司信息</div>
            <div class="table-body-item">提交经营资质</div>
            <div class="table-body-item">确认在线协议</div>
          </div>
          <div class="settled-table-body">
            <div class="table-body-item">入驻审核<i class="iconfont arrows">&#xe657;</i></div>
            <div class="table-body-item">资质初审</div>
            <div class="table-body-item">招商复审</div>
            <div class="table-body-item">店铺授权</div>
          </div>
          <div class="settled-table-body">
            <div class="table-body-item">开店任务<i class="iconfont arrows">&#xe657;</i></div>
            <div class="table-body-item">维护信息地址</div>
            <div class="table-body-item">开通结算账户<i class="iconfont warning">&#xe685;</i></div>
            <div class="table-body-item">缴费、开店考试</div>
          </div>
          <div class="settled-table-body">
            <div class="table-body-item">上线经营</div>
            <div class="table-body-item">发布商品信息</div>
            <div class="table-body-item">装修店铺页面</div>
            <div class="table-body-item">上线销售</div>
          </div>
        </div>
      </div>
      <div class="make-settled-two">
        <div class="make-settled-title">二.请认真查阅入驻相关说明，准备入驻资质：</div>
        <div class="make-settled-qualification">
          <div class="settled-qualification-item">
            <div class="qualification-item-top">
              <i class="iconfont" style="margin-left: 10px">&#xe635;</i>
              <span>资质标准</span>
            </div>
            <div class="qualification-item-bottom">
              招商基础资质标准
            </div>
          </div>
          <div class="settled-qualification-item">
            <div class="qualification-item-top">
              <i class="iconfont">&#xe605;</i>
              <span>重点品牌</span>
            </div>
            <div class="qualification-item-bottom">
              开放平台重点招募品牌
            </div>
          </div>
          <div class="settled-qualification-item">
            <div class="qualification-item-top">
              <i class="iconfont">&#xe64e;</i>
              <span>资费标准</span>
            </div>
            <div class="qualification-item-bottom">
              开放平台各类目资费一览表
            </div>
          </div>
          <div class="settled-qualification-item">
            <div class="qualification-item-top">
              <i class="iconfont">&#xe618;</i>
              <span>常见问题</span>
            </div>
            <div class="qualification-item-bottom">
              入驻审核常见问题
            </div>
          </div>
        </div>
      </div>
      <div class="make-settled-three">
        <div class="make-settled-title">三.请再次确认您是否已准备好相应的资质</div>
        <div class="settled-three-multiple">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="1">我已准备好公司营业执照、法人等相关信息及资质电子图片</el-checkbox>
            <el-checkbox label="2">我已准备好经营品牌授权、行业及产品资质信息及资质电子图片</el-checkbox>
            <el-checkbox label="3">我已准备实名认证通过的京东钱包/正在提交实名认证《注册京东企业钱包及提交实名操作说明》</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="lastStep">返回重选</el-button>
      <el-button @click="nextStep" type="primary" style="margin-left: 20px">开始提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrepareMaterial",
  props:['stepValue'],
  data(){
    return {
      checkList:[],
    }
  },
  mounted() {
  },
  methods:{
    lastStep(){
      let exam_type = Number(localStorage.getItem('exam_type'));
      if (exam_type === 1) {
          this.$router.push('/student/examing/onlineShopDecoration/settledJD');
      } else {
        this.$router.push('/student/trainCenter/startTraining/settledJD')
      }
    },
    nextStep(){
      if(this.checkList.length === 3){
        this.$emit('update:stepValue', 2)
      } else {
       this.$message('请勾选确认您是否已准备好相应的资质')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    padding: 22px 0 10px 74px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .make-box-header{
    line-height: 1;
    color: #666666;
    font-size: 12px;
  }
  .make-settled-one{
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    .make-settled-title{
      font-size: 16px;
      line-height: 1;
    }
    .make-settled-table{
      display: flex;
      margin: 29px 0 0 53px;
      .settled-table-body{
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #E5E5E5;
        border-right: none;
        &:nth-child(odd){
          background: #F8F8F8;
        }
        &:last-of-type{
          border-right: 1px solid #E5E5E5;
        }
        .table-body-item{
          width: 200px;
          text-align: center;
          line-height: 1;
          margin: 25px 0;
          font-size: 14px;
          .warning{
            font-size: 14px;
            color: #FFAA00;
            margin-left: 6px;
          }
          .arrows{
            font-size: 40px;
            color: #E1E1E1;
            position: absolute;
            right: -26px;
            top: 5px;
          }
          &:first-of-type{
            padding: 17px 0;
            border-bottom: 1px solid #E5E5E5;
            margin: 0;
            font-size: 16px;
            position: relative;
          }
        }
      }
    }
  }
  .make-settled-two{
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    .make-settled-title{
      font-size: 16px;
      line-height: 1;
    }
    .make-settled-qualification{
      display: flex;
      margin: 26px 0 0 53px;
      .settled-qualification-item{
        display: flex;
        flex-direction: column;
        width: 150px;
        margin-left: 75px;
        &:first-of-type{
          margin-left: 0;
        }
        .qualification-item-top{
          display: flex;
          flex-direction: column;
          background: #2DC079;
          line-height: 1;
          align-items: center;
          padding: 30px 0 22px;
          i{
            color: #FFFFFF;
            font-size: 50px;
          }
          span{
            margin-top: 10px;
            color: #FFFFFF;
            font-size: 20px;
          }
        }
        .qualification-item-bottom{
          text-align: center;
          font-size: 12px;
          color: #666666;
          line-height: 1;
          margin-top: 10px;
        }
      }
    }
  }
  .make-settled-three{
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    .make-settled-title{
      font-size: 16px;
      line-height: 1;
    }
    .settled-three-multiple{
      display: flex;
      flex-direction: column;
      ::v-deep .el-checkbox-group{
        display: flex;
        flex-direction: column;
        margin: 14px 0 0 45px;
         .el-checkbox__label{
           font-size: 12px;
           color: #666666;
           line-height: 36px;
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
</style>